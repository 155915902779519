<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <h2 class="title"><span class="title_icon"></span>{{ isEdit ? "编辑" : "添加" }}订单</h2>
      <!--搜索条件区域-->
      <div class="Wrapper">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 330px; float: left"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
            <el-input
              :disabled="!parkCardIsActive && !isNowDate"
              :maxlength="8"
              v-model.trim="formInline.plateNumber"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.License_plate_color')" prop="plateColor">
            <el-select
              :disabled="!parkCardIsActive && !isNowDate"
              v-model.trim="formInline.plateColor"
              placeholder="请选择车牌颜色"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="phoneNumber">
            <el-input
              :disabled="isEdit"
              :maxlength="11"
              v-model.trim="formInline.phoneNumber"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Parking_card_name')" prop="parkCardRuleId">
            <el-select
              :disabled="isEdit"
              filterable
              v-model="formInline.parkCardRuleId"
              placeholder="请选择"
              @change="parkCardRuleIdChange"
            >
              <el-option
                :label="item.name"
                :value="item.parkCardRuleId"
                :key="item.parkCardRuleId"
                v-for="item in ruleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用范围" class="is-required" style="width: 630px">
            <el-input
              disabled
              :autosize="{ minRows: 2 }"
              type="textarea"
              v-model="formInline.useRangeDesc"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="生效开始日期"
            disabled
            prop="effectStartDate"
            style="width: 400px"
            v-if="formInline.effectType === 0"
          >
            <el-date-picker
              disabled
              v-model="formInline.effectStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="生效结束日期"
            prop="effectEndDate"
            style="width: 400px"
            v-if="formInline.effectType === 0"
          >
            <el-date-picker
              disabled
              v-model="formInline.effectEndDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label=" "
            label-width="1px"
            prop="effectDaysAfterBuy"
            v-if="formInline.effectType === 1"
          >
            <div style="color: #606266">
              自购买后<span style="color: #0f6eff; font-size: 20px">
                {{ formInline.effectDaysAfterBuy }}天 </span
              >开始生效
            </div>
          </el-form-item>
          <el-form-item label="有效天数" class="is-required" v-if="formInline.effectType === 1">
            <el-input :maxlength="100" disabled v-model.trim="formInline.validityPeriod"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="addEdit" style="width: 88px">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
export default {
  name: "hello",
  data() {
    let plateNumberRules = (rule, value, callback) => {
      let rel =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
      if (rel.test(value)) {
        callback();
      } else {
        callback("车牌号格式不正确，例：冀AAAAAA");
      }
    };
    let effectStartDaterRules = (rule, value, callback) => {
      let now = new Date().getTime();
      if (new Date(value).getTime() <= now) {
        callback("生效日期必须大于当前日期");
      } else if (new Date(value).getTime() < this.ruleStartTime) {
        callback("生效开始日期与停车卡规则不符");
      } else {
        callback();
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (value && !/^\d{11}$/.test(value)) {
        callback("手机号格式不正确");
        return false;
      } else {
        return callback();
      }
    };
    return {
      parkCardIsActive: true,
      isNowDate: true,
      ruleStartTime: "",
      ruleList: [],
      colorList: [],
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      workStateAll: [],
      parkTypeAll: [],
      parkList: "",
      areaList: [],
      isEdit: false,
      loading: false,
      startTime: "",
      formInline: {
        scope: 1,
        plateNumber: "",
        plateColor: 0,
        phoneNumber: "",
        parkCardRuleId: "",
        effectStartDate: "",
        validityPeriod: "",
        useRangeDesc: "",
      },
      rules: {
        phoneNumber: [{ validator: checkPhone, trigger: "blur" }],
        plateNumber: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: plateNumberRules, trigger: "blur" },
        ],
        plateColor: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        parkCardRuleId: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        effectStartDate: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: effectStartDaterRules, trigger: "blur" },
        ],
        effectEndDate: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        effectDaysAfterBuy: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    parkCardRuleIdChange() {
      let parksName = [];
      let parks = null;
      this.ruleList.forEach((v) => {
        if (v.parkCardRuleId == this.formInline.parkCardRuleId) {
          parks = v.parks;
          this.formInline.validityPeriod = v.validityPeriod;
          // this.ruleStartTime = v.effectStartTime;
          this.formInline.effectType = v.effectType;
          this.formInline.effectDaysAfterBuy = v.effectDaysAfterBuy;
          this.formInline.effectStartDate = this.$moment(parseInt(v.effectStartTime)).format(
            "YYYY-MM-DD"
          );
          this.formInline.effectEndDate = this.$moment(parseInt(v.effectEndTime)).format(
            "YYYY-MM-DD"
          );
        }
      });
      parks.forEach((v) => {
        parksName.push(v.parkName);
      });
      this.formInline.useRangeDesc = parksName.toString();
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    getDetail() {
      this.$axios
        .get(`/acb/2.0/parkCard/detail`, {
          data: {
            parkCardId: this.$route.query.parkCardId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            let createdTime = this.formInline.createTime.substring(0, 10);
            let newDate = dateFormat(new Date(), "yyyy-MM-dd");
            this.isNowDate = createdTime === newDate;
            // 查看停车卡是否已生效
            this.parkCardIsActive = this.formInline.state == 1;
            this.formInline.plateColor = res.value.plateColorCode;
            this.parkCardRuleIdChange(this.formInline.parkCardRuleId);
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取停车卡名称列表
    getRulelist() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/parkCardRule/list", {
            data: {
              appSale: 0,
              states: 2,
              page: 0,
              pageSize: 0,
            },
          })
          .then((res) => {
            let ruleList = res.value.list;
            if (this.isEdit) {
              let flag = false;
              for (let i = 0; i < ruleList.length; i++) {
                if (ruleList[i].parkCardRuleId == this.$route.query.parkCardRuleId) {
                  flag = true;
                }
              }
              setTimeout(() => {
                if (!flag) {
                  ruleList.push({
                    parkCardRuleId: this.$route.query.parkCardRuleId,
                    name: this.$route.query.parkCardTitle,
                  });
                }
              }, 100);
            }
            this.ruleList = ruleList;
            resolve();
          });
      });
    },
    addEdit() {
      let url = this.isEdit ? "/acb/2.0/parkCard/update" : "/acb/2.0/parkCard/add";
      const data = {
        scope: this.formInline.scope,
        plateNumber: this.formInline.plateNumber,
        plateColor: this.formInline.plateColor,
        phoneNumber: this.formInline.phoneNumber,
        parkCardRuleId: this.formInline.parkCardRuleId,
      };
      if (this.isEdit) {
        data.parkCardId = this.$route.query.parkCardId;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let method = "post";
          this.$axios[method](url, {
            data,
          }).then((res) => {
            if (res.state == 0) {
              this.$router.go(-1);
            } else {
              this.$alert(res.desc, "提示");
            }
          });
        }
      });
    },
  },
  components: {},
  created() {
    // 获取车牌颜色
    this.getColor();
    // 获取停车卡名称列表
    this.getRulelist().then(() => {
      if (this.$route.query.parkCardId) {
        this.isEdit = true;
        this.getDetail();
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height: 35px;
.content
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
</style>
